
<template>
  <div>
    <el-dialog
      :title="titles"
      :visible.sync="visible"
      width="50%"
      :z-index="99"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <ax-form ref="formBox" :formBuilder="formBuilder"> </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          style="background-color: #556bff"
          :loading="saveType"
          @click="confirm"
          >提 交</el-button
        >
        <el-button @click="mapCancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
   
   <script>
import api from "../api";
const formList = [
  {
    label: "服务项目",
    type: "",
    model: "name",
    options: { placeholder: "请输入事项名称", maxLength: 50 },
    col: { span: 24 },
    rules: [{ required: true, message: "请正确输入事项名称" }],
  },
  {
    label: "服务费用",
    type: "inputNumber",
    model: "price",
    options: { placeholder: "请输入服务费用",min:"0.01"},
    col: { span: 12 },
    rules: [{ required: true, message: "请正确输入服务费用" }],
  },
  {
    label: "服务原价",
    type: "inputNumber",
    model: "originalPrice",
    options: { placeholder: "请输入服务原价",min:"0.01" },
    col: { span: 12 },
    rules: [{ required: true, message: "请正确输入服务原价" }],
  },

  {
    label: "服务状态",
    type: "radio",
    model: "publishStatus",
    options: {
      options: [
        { label: "上架", value: 1 },
        { label: "下架", value: 0 },
      ],
    },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择是否上架" }],
  },
  {
    label: "排序",
    type: "inputNumber",
    model: "sort",
    options: { placeholder: "请输入数字", max: 99999 },
    col: { span: 24 },
    rules: [{ required: true, message: "请正确输入数字" }],
  },
  {
    label: "服务说明",
    type: "textarea",
    model: "description",
    options: { minRows: 4, maxRows: 4, placeholder: "请输入服务说明" },
    col: { span: 24 },
    rules: [{ required: true, message: "请正确输入服务说明" }],
  },
];
export default {
  components: {},
  data() {
    return {
      api,
      visible: false,
      titles: "",
      info: {},
      saveType: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },

  methods: {
    openDialog(e) {
      this.visible = true;
      this.titles = e.titles;
      if (e.record) {
        this.info = e.record;
        console.log(e.record);
        
        this.$nextTick(()=>{
            this.$refs.formBox.setFieldsValue(e.record); 
        })
      }
    },
    mapCancel() {
      this.visible = false;
      this.$refs.formBox.resetFields();
    },
    async confirm() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        this.saveType = true;
        try {
          if (this.titles === "新增服务") {
            const res = await api.add(value);
            if (res.status == 200) {
              this.$message.success("操作成功");
              this.mapCancel();
              this.saveType = false;
              this.$emit("refresh");
            } else {
              this.$message.error("操作失败");
              this.saveType = false;
            }
          } else {
            value.id = this.info.id;
            const res = await api.edit(value);
            if (res.status == 200) {
              this.$message.success("操作成功");
              this.mapCancel();
              this.saveType = false;
              this.$emit("refresh");
            } else {
              this.$message.error("操作失败");
              this.saveType = false;
            }
          }

          this.saveType = false;
        } catch (error) {
          console.log(error);
        }
      });
    },
  },

  created() {},

  mounted() {},
};
</script>
   <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>